@import "../../styles/variables";

.exception_wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.exception_title {
  margin: 0;
  padding: 0.5rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .wrapper_details {
    align-self: stretch;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 5rem;
  }

  .sizing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 5rem;
    padding: 0.5rem;

    .approved_sizing,
    .actual_sizing {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      line-height: 2rem;
      
      .sizing_title {
        font-weight: bold;
      }
    }

  }
}
