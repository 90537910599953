.systemDetails__wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;

  .system_header {
    display: flex;
  }

  .details_wrapper {
    align-self: stretch;
    margin-top: 1rem;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 5rem;
  }
}
