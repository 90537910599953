@import "../../styles/variables";

.compliance__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  margin: 2rem;

  .compliance__results {
    padding: 2rem;
    margin: 2rem 0;
    width: 100%;
    border: 2px solid rgba($blue, 0.2);
    border-radius: 2rem;

    .compliance__results_title {
      font-weight: 700;
      color: rgba($blue, 0.1);
      font-size: 2.25rem;
      text-align: left;
      margin-bottom: 1rem;
    }
  }
}
