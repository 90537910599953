@import "../../styles/variables";

.bordered__wrapper {
  border-radius: 1rem;
  border: 1px solid rgba($blue, 0.2);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
