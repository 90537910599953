@import "../../styles/variables";

.pill__wrapper {
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  background-color: $blue;
  color: $green;

  &.error {
    background-color: $pink;
    color: $grey;
  }
}
