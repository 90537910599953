@import "../../styles/variables";

.criteria__listItem {
  display: flex;
  flex-direction: column;
  color: $blue;
  margin-bottom: 1rem;

  .criteria__header {
    position: relative;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 1.5rem;
    border: 2px solid rgba($blue, 0.2);
    border-radius: 1rem;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      // background: linear-gradient(
      //   -25deg,
      //   rgba($green, 1) 0%,
      //   rgba($green, 0.1) 50%,
      //   rgba($pink, 0) 100%
      // );
    }

    .criteria__header_left,
    .criteria__header_right {
      flex: 1;
      display: flex;
      z-index: 11;
    }

    .criteria__header_left {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .criteria__label {
        font-size: 0.875rem;
        font-weight: 700;
      }
    }

    .criteria__header_center {
      flex: 10;
      padding-left: 5rem;
      text-align: start;
      z-index: 11;
    }

    .criteria__header_right {
      justify-content: flex-end;
      background-color: $blue;
      border-radius: 1.5rem;
      color: $grey;
      padding: 0.5rem;
    }
  }

  .criteria__collapsible {
    margin-top: -1.625rem;
    padding-top: 1.25rem;
    border: 2px solid rgba($blue, 0.2);
    border-radius: 1rem;

    .criteria__validations_wrapper {
      margin-top: 0.75rem;
      padding: 0.75rem;
    }
  }
}
