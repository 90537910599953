@import "../../styles/variables";

.landing__wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  .landing__header {
    position: relative;
    background-color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem;

    svg {
      width: 7rem;
      height: auto;
    }

    .landing__cta {
      position: absolute;
      bottom: -1.375rem;
    }

    .landing__title {
      padding-left: 0.25rem;
      font-weight: 700;
      color: #ffffff;
      font-size: 2.5rem;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 1rem;
        right: 0.125rem;
        bottom: 0.3125rem;
        height: 0.25rem;
        background-color: $green;
      }
    }
  }

  .landing__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .power_bi__iframe {
      width: 80%;
      aspect-ratio: 16/9;
    }

    .content__power_bi_button {
      margin-top: 3rem;
      font-family: "Poppins", sans-serif;
      padding: 0.5rem 2rem;
      border-radius: 2rem;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.75rem;
      outline: none;
      border: none;
      background-color: $blue;
      color: $green;
      display: flex;
      gap: 0.5rem;
      cursor: pointer;
      transition: box-shadow 0.2s ease-in-out;
    }
  }

  .landing__footer {
    display: flex;
    margin-top: auto;
    background-color: $green;

    .footer__left {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4rem;
      background-color: $blue;
    }

    .footer__right {
      padding: 1rem;

      .imdl_roles__desc {
        font-size: smaller;
      }
    }
  }
}
