@import "../../styles/variables";

.scoreHeader__wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  
  .scoreHeader_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .score_header {
      display: flex;
      width: 98%;
    }
    .scoreHeader__legend {
      text-align: left;
    }

    .scoreHeader__legend {
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .scoreHeader__legend_weight,
      .scoreHeader__legend_value {
        display: flex;
        align-items: center;
        justify-content: center;

        .scoreHeader__legend_label {
          margin-left: 0.25rem;
        }
      }

      .scoreHeader__legend_value {
        margin-left: 2rem;
      }
    }
  }
}
