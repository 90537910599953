.completion__value {
  margin-left: 0.5rem;
  width: 2.5rem;
  text-align: right;
}

.completion__expand {
  display: inline;
  transform: rotate(180deg);
  transition: all 0.3s ease-out;
  margin-left: 0.5rem;
  line-height: 1.5rem;

  svg {
    display: block;
  }

  &.completion__expand_rotate {
    transform: rotate(0);
  }
}
