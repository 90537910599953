@import "../../styles/variables";

.card {
  overflow: hidden;
  border-radius: 1rem;
  padding: 1rem;
  border: 2px solid rgba($blue, 0.2);
  box-shadow: 0 0.125rem 1rem rgba($blue, 0.25);
  display: flex;
  flex-direction: column;
  min-height: 11rem;
}
