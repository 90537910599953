.layout {
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr;

  .content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }
}
