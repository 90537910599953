@import "../../styles/variables";

.systemItem__wrapper {
  display: flex;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid $blue;
  gap: 1rem;
  transform: translate3d(0, 0, 0);

  &:hover {
    background-color: rgba($blue, 0.03);
  }

  &:last-child {
    border-bottom: none;
  }

  .systemItem__column {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.25rem;
    overflow: hidden;

    .label {
      font-weight: 300;
      font-size: 0.75rem;
      color: rgba($blue, 0.5);
    }

    .value {
      display: flex;
      gap: 0.5rem;
      max-width: 100%;
      font-weight: 500;
      font-size: 1.125rem;
      color: $blue;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
    }

    &:first-child {
      flex: 2;
    }
  }

  .systemItem__column_action {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
