@import "../../styles/variables";

.status_main_wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;

  .status_header {
    display: flex;
  }

  
}
