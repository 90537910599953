@import "../../styles/variables";

.apiDetails__wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;

  .apiDetails_header {
    display: flex;
  }

  .details_wrapper {
    margin-top: 1rem;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 5rem;
  }

  .details__system_title {
    display: flex;
    align-items: center;
    gap: 1rem;

    .search__wrapper {
      margin-left: auto;
    }

    .icon__link {
      color: $green;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 6px;
        bottom: 6px;
        left: 8px;
        right: 8px;
        z-index: -1;
        background-color: $blue;
        border-radius: 50%;
      }

      svg {
        display: block;
      }
    }
  }

  .pill_area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .systems__list {
    display: flex;
    flex-direction: column;
    max-height: 78vh;
    overflow-y: scroll;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 3rem;
  padding: 2rem;
  background-color: $grey;
  border-radius: 1rem;
  min-width: 25rem;
  max-width: 45rem;
}

.overlay {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: rgba($blue, 0.5);
  backdrop-filter: blur(0.125rem);
  align-items: center;
  justify-content: center;
}

.modal__title {
  font-weight: 700;
  font-size: 1.25rem;
}

.modal__buttons {
  justify-content: flex-end;
  display: flex;
  gap: 1rem;
}
