@import "../../styles/variables";

.status {
  align-self: flex-end;
  
  .status_label {
    color: $grey;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 0 0.35rem;
    line-height: 1.125rem;
    align-self: flex-start;
  }
  
  .status_color_approved {
    background-color: $darker_green;
  }
  
  .status_color_rejected {
    background-color: $red;
  }
  
  .status_color_to_be_reviewed {
    color: $black;
    background-color: $grey;
  }
}
