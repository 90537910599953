@import "../../styles/variables";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    color: $yellow;
  }

  .label {
    font-weight: 500;
    font-size: 0.875rem;
  }
}
