@import "../../styles/variables";

.value__wrap {
  display: flex;
  align-items: center;

  .link_fix {
    position: relative;
    margin-right: 0.5rem;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: 2px;
      left: 2px;
      background-color: $blue;
      z-index: -1;
      border-radius: 50%;
    }

    svg {
      display: block;
    }
  }
}
