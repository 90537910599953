@import "../../styles/variables";

.history_main_wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;

  .history_header {
    display: flex;
  }
  
  .history_filter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

  .history_review_wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .history_review_title {
    margin: 0;
    padding: 0.5rem;
  }

  .history_wrapper {
    align-self: stretch;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
    column-gap: 5rem;
  }

  .history_title_main {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
}
