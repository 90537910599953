@import "../../styles/variables";

.filterDropdown__wrapper {
  position: relative;
  padding: 0.2rem;
  border: 1px solid $blue;
  outline: none;
  border-radius: 1rem;
  font-size: 1rem;
  width: fit-content;

  .filterDropdown__option {
    width: 100%;
    padding: 0.5rem 3rem 0.5rem 1rem;
    border: 1px solid $blue;
    outline: none;
    border-radius: 1rem;
    font-size: 1.125rem;
  }
}