@import "../../styles/variables";

.show_pointer {
  cursor: pointer;
}

.top {
  display: flex;

  .details {
    flex: 8;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .name,
    .platform,
    .hasProduct {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    .hasProduct,
    .platform {
      border-radius: 0.5rem;
      line-height: 1.25rem;
      font-weight: 300;
      font-size: 0.75rem;
    }
  }

  .version {
    flex: 2;
    text-align: right;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}

.bottom {
  margin-top: auto;
  display: flex;

  .bottom_labels {
    width: 100%;
    align-self: flex-end;
  }

  .apiExceptionStatus {
    color: $grey;
    display: flex;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 0 0.35rem;
    width: fit-content;
    margin-bottom: 0.3rem;

    &.apiExceptionStatus_color_not_approved {
      background-color: $red;
    }
    &.apiExceptionStatus_color_limit_near {
      background-color: $yellow;
    }
    &.apiExceptionStatus_color_approved {
      background-color: $green;
    }
    &.apiExceptionStatus_color_no_size_info {
      color: $black;
      background-color: $grey;
    }
  }

  .bottom_status_layer {
    display: flex;
  }

  .layer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 5%;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 0 0.35rem;
    line-height: 1.125rem;
    align-self: flex-end;

    &.layer_color_system {
      background-color: $light_blue;
    }
    &.layer_color_experience {
      background-color: #c8d39a85;
    }
    &.layer_color_process {
      background-color: #ecc1c978;
    }
  }

  .environments {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    margin-left: auto;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
}
