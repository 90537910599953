@import "../../styles/variables";

.searchBox__wrapper {
  position: relative;

  .searchBox__input {
    width: 100%;
    padding: 0.5rem 3rem 0.5rem 1rem;
    border: 1px solid $blue;
    outline: none;
    border-radius: 1rem;
    font-size: 1.125rem;

    &:focus {
      border: 1px solid $green;
    }
  }

  .searchBox__iconWrapper {
    position: absolute;
    top: 0.5rem;
    right: 1rem;
  }
}
