@import "../../styles/variables";

.pageHeader__wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;

  .pageHeader__bookmark,
  .pageHeader__title,
  .pageHeader__subtitle {
    text-align: left;
  }

  .pageHeader__bookmark {
    font-weight: 900;
    font-size: 1.5rem;
    line-height: 2rem;
    color: rgba($blue, 0.1);
  }

  .pageHeader__title {
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3.375rem;
  }

  .pageHeader__subtitle {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1rem;
    color: rgba($blue, 0.6);
  }
}
