@import "../../styles/variables";

.validation__listItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .validation__header {
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 2px solid rgba($blue, 0.2);
    padding: 1.25rem;
    border-radius: 1rem;

    .validation__header_left,
    .validation__header_right {
      display: flex;
    }

    .validation__header_left {
      align-items: center;
      gap: 1rem;
      flex: 3;

      .validation__label {
        font-size: 1.125rem;
        font-weight: 400;
      }
    }

    .validation__header_right {
      flex: 1;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .validation__collapsible {
    background-color: $grey;
    padding-top: 1.1rem;
    margin-top: -1.5rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}
