@import "../../styles/variables";

.button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  align-self: flex-start;
  font-weight: bold;
  font-family: $font-family;
  
  p {
    display: inline-block;
    transition: 0.1s ease-in;
    
  }
  &:hover p{
      transform: translateX(-50%);
    }
}

