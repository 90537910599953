@import "../../styles/variables";

.nav {
  padding: 0.5rem;
  display: flex;

  .bar {
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: $blue;

    .icon {
      margin-top: 1rem;
      padding: 1rem;
      border-radius: 1.5rem;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
      color: $green;

      &.bordered {
        border: 1px solid $green;
      }

      &:hover {
        color: $blue;
        background-color: $green;
      }

      &.active {
        color: $blue;
        background-color: $green;
      }

      svg {
        display: block;
      }
    }

    .support {
      margin-top: auto;
    }
  }
}
