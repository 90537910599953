@import "../../styles/variables";

.show_pointer {
  cursor: pointer;
}

.top {
  display: flex;
  .details {
    flex: 8;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .name,
    .platform,
    .system {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    .system,
    .platform {
      border-radius: 0.5rem;
      line-height: 1.25rem;
      font-weight: 300;
      font-size: 0.75rem;
    }

    .system.unavailable {
      background-color: $pink;
      color: $grey;
      padding: 0 0.25rem;
      align-self: flex-start;
      font-weight: 500;
    }
  }

  .version {
    flex: 2;
    text-align: right;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}

.bottom {
  margin-top: auto;
  display: flex;

  .score {
    .score_label {
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    .scorePercentage__bars {
      position: relative;
      height: 0.375rem;
      width: 4rem;

      .scorePercentage__bars_background,
      .scorePercentage__bars_value {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 0.5rem;
      }

      .scorePercentage__bars_background {
        background-color: $grey;
        right: 0;
      }

      .scorePercentage__bars_value {
        z-index: 1000;
        animation: scaler 1s ease-out;
        transform-origin: left;

        &.medium {
          background-color: $yellow;
        }

        &.high {
          background-color: $green;
        }
      }
    }
  }

  .owner {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: auto;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }

  .layer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 5%;
    font-size: 0.75rem;
    font-weight: 500;
    border-radius: 0.5rem;
    padding: 0 0.35rem;
    line-height: 1.125rem;
    align-self: flex-end;
    background-color: $light_blue;
  }

  .environments {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    margin-left: auto;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
}

@keyframes scaler {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
