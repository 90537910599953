@import "../../styles/variables";

.wrapper {
  position: relative;
  display: flex;

  .holder {
    cursor: pointer;
  }

  .content {
    visibility: hidden;
    position: absolute;
    background-color: $green;
    padding: 2rem;
    bottom: 0;
    left: 110%;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 1rem rgba($blue, 0.25);
    z-index: 9999;

    &.isVisible {
      visibility: visible;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 1.25rem;
      left: -0.875rem;
      margin-top: -0.5rem;
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent $green transparent transparent;
      pointer-events: none;
    }
  }
}
