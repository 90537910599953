@import "../../styles/variables";

.loader__placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1;

  .loader {
    position: relative;
    width: 2rem;
    height: 2rem;
    border: 3px solid $blue;
    border-radius: 0.75rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    overflow: hidden;
    animation: spin 3s ease infinite;
    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: 2rem;
      height: 2rem;
      background-color: $green;
      transform-origin: center bottom;
      transform: scaleY(1);
      animation: fill 3s linear infinite;
    }
  }
}
@keyframes spin {
  50%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fill {
  25%,
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
