@import "../../styles/variables";

.exception_wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.exception_title {
  margin: 0;
  padding: 0.5rem;
}

.wrapper {
  align-self: stretch;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1.5rem;
  column-gap: 5rem;
}
