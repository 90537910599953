@import "../../styles/variables";

.wrapper {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  box-shadow: 2px 2px 15px rgba($blue, 0.25);
  border-radius: 2.5rem;

  .previous,
  .next {
    border: none;
    background: none;

    cursor: pointer;
    svg {
      transition: all 0.3s ease-out;
      color: $blue;
      display: block;
    }

    &:hover {
      svg {
        color: $green;
      }
    }

    .disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }

  .label_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    .label {
      color: rgba($blue, 0.5);
      font-size: 0.5rem;
      font-weight: 300;
    }

    .page {
      // margin-top: -0.25rem;
      font-size: 0.75rem;
    }
  }
}
