@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

$font-family: "Poppins", sans-serif;

$black: #000913;
$blue: #001935;
$light_blue: #BAE1F2;
$green: #17dcb8;
$darker_green: #0f7563;
$yellow: #f4c478;
$red: #a31a21;
$pink: #e65571;
$grey: #eeeeee;
$hard_grey: #c4c4c4;
