.weightLabel {
  display: flex;
  align-items: flex-end;

  .weightLabel__percentage {
    margin-left: 0.25rem;
    line-height: 0.875rem;
    font-weight: 200;
    font-size: 0.875rem;

    &.weightLabel__percentage_large {
      font-size: 1.125rem;
      line-height: 1.125rem;
    }
  }
}
