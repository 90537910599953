.apiList__wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  flex: 1;

  .apiList__header {
    display: flex;
  }

  .apiList__messages {
    display: flex;
    padding: 2rem 0;
  }

  .apiList__list {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 1.5rem;
  }
}
