@import "../../styles/variables";

.step__listItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid white;

  &:last-child {
    border-bottom: none;
  }

  .step__left {
    display: flex;
    flex: 3;
    align-items: center;
    gap: 1rem;

    .step__label {
      font-size: 1.125rem;
    }
  }

  .step__right {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    color: $green;

    &.step__right_failed {
      color: $pink;
    }
  }
}
