@import "../../styles/variables";

.apiListV1__wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  flex: 1;

  .apiList__header {
    display: flex;
  }

  .apiList__messages {
    display: flex;
    padding: 2rem 0;
  }

  .apiList__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-gap: 1.5rem;
  }

  .apiList__search {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 70%;
    height: 10rem;

    .apiList__filters {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;

      .apiList__filters_top {
        display: flex;
        gap: 1rem;
      }

      .apiList__filters_bottom {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        gap: 1rem;
      }
    }
  }
}
