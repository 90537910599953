@import "../../styles/variables";

.field__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;

  .field__name {
    font-size: 0.875rem;
    color: rgba($blue, 0.5);
  }

  .field__value {
    color: $blue;
    font-size: 1.125rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 2rem;

    svg {
      color: $green;
    }
  }
}
