@import "../../styles/variables";

.cta_button {
  font-family: $font-family;
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.75rem;
  outline: none;
  border: none;
  background-color: $green;
  color: $blue;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  box-shadow: 0.125rem 0.125rem 1rem rgba(#000000, 0.25);
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0.25rem 0.25rem 1rem rgba(#000000, 0.4);
  }

  &:active {
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(#000000, 0.25);
  }

  &.secondary{
    background-color: $grey;
  }
}
