@import "../../styles/variables";

.scorePercentage__card {
  display: flex;
  flex-direction: column;
  background-color: $blue;
  border-radius: 1.5rem;
  box-shadow: 0 4px 15px rgba($blue, 0.25);
  padding: 2rem;

  .scorePercentage__title {
    font-weight: 500;
    font-size: 0.875rem;
    color: $hard_grey;
    align-self: flex-start;
  }

  .scorePercentage__percentage {
    font-weight: 700;
    font-size: 3.5rem;
    color: $grey;
    align-self: flex-end;
    line-height: 6.5rem;
  }

  .scorePercentage__bars {
    position: relative;
    height: 0.5rem;
    width: 12.5rem;

    .scorePercentage__bars_background,
    .scorePercentage__bars_value {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 0.5rem;
    }

    .scorePercentage__bars_background {
      background-color: $grey;
      right: 0;
    }

    .scorePercentage__bars_value {
      z-index: 1000;
      animation: scaler 1s ease-out;
      transform-origin: left;

      &.medium {
        background-color: $yellow;
      }

      &.high {
        background-color: $green;
      }
    }
  }
}

@keyframes scaler {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
