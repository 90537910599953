@import "../../styles/variables";

.score {
  .score_label {
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .scorePercentage__bars {
    position: relative;
    height: 0.375rem;
    width: 4rem;

    .scorePercentage__bars_background,
    .scorePercentage__bars_value {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 0.5rem;
    }

    .scorePercentage__bars_background {
      background-color: $grey;
      right: 0;
    }

    .scorePercentage__bars_value {
      z-index: 1000;
      animation: scaler 1s ease-out;
      transform-origin: left;

      &.medium {
        background-color: $yellow;
      }

      &.high {
        background-color: $green;
      }
    }
  }
}

@keyframes scaler {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
